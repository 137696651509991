.unipack-main {

}

.ant-layout-header {
    padding: 0 !important;
    background: #7098f5 !important;
}

.unipack-header {
    border-radius: 0 !important;
    margin-bottom: 0 !important;
    background: orange !important;
}

.unipack-left-side-bar {
    border: none !important;
    height: calc(100vh - 56px) !important;
}

.login-unpack {
    padding: 15% 35% 0 35%;
}

.logo {

}

.unipack-main-content {
    padding-top: 64px;
}

.site-layout .site-layout-background {
    background: #fff;
}

.subCategory_unipack {
    background: #cccccc;

}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    background: #a0bcfc !important;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a {
    color: rgb(0 21 41) !important;
}

.ant-menu-submenu-title {
    color: rgb(0 21 41) !important;
}

.ant-layout-sider {
    background: #a0bcfc !important;
}

.sub-SubCategory_unipack {
    background: #ddd;
}

.ant-table-container {
    overflow-x: scroll;
}

.ant-layout-sider, .ant-layout-sider-dark {
    top: 64px;
}

.ant-layout-header {
    position: fixed;
    z-index: 5 !important;
}

.ant-menu-inline .ant-menu-item {
    margin-top: 0 !important;
}

.ant-table-tbody > tr > td {
    padding: 9px 16px !important;
    white-space: nowrap;
}

.orders__search__unipack {
    text-align: left;
    max-width: 500px;
    display: flex;
    margin: 15px;
    justify-content: space-between;
}

.products__search__unipack {
    text-align: left;
    display: flex;
    margin: 15px;
    justify-content: space-between;

}

.products__search__unipack-div {
    text-align: center;
    margin: 15px;
    white-space: nowrap;
}

.orders__search__select__unipack {
    min-width: 94px;
    text-align: center;
}

/*//userInfoTab*/

.user__info__unipack--container {
    border: 1px solid black;
    width: 70%;
}

.user__info__unipack--row {
    display: flex;
}

.user__info__unipack--keys {
    border: 1px solid black;
    padding: 3px;
    background: #dddddd;
    font-weight: bold;
    width: 30%;
}

.user__info__unipack--values {
    border: 1px solid black;
    padding: 3px;
    width: 80%;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

.unipack-global_settings{
    font-weight: 700;
    color: darkblue;
    margin-bottom: 20px;
    margin-top: 10px;
}

.site-input-group-wrapper .site-input-split {
    background-color: #fff;
}

.site-input-group-wrapper .site-input-right {
    border-left-width: 0;
}

.site-input-group-wrapper .site-input-right:hover,
.site-input-group-wrapper .site-input-right:focus {
    border-left-width: 1px;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right {
    border-right-width: 0;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-right:focus {
    border-right-width: 1px;
}

.ant-card-cover > * {
    width: 99% !important;
}

.order__info__unipack--container{

}

.order__info__unipack--card{
    background-color: #eaeaec;
    margin: 0 5px 0 15px;
    border-radius: 5px;
}

.order__info__unipack--card-header{
    font-weight: 700;
    display: flex;
    justify-content: space-between;
}
